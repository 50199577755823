import request from '@/utils/request'

// 订单列表
export function getList(params) {
    return request({
        url: '/v1/mall/order/list?type=' + params.type + '&page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

// 创建订单
export function createOrder(params) {
    return request({
        url: '/v1/mall/order/create',
        method: 'post',
        data: params
    })
}

// 订单详情
export function getOrderInfo(orderId) {
    return request({
        url: '/v1/mall/order/info?orderId=' + orderId,
        method: 'get'
    })
}

// 支付
export function orderPay(params) {
    return request({
        url: '/v1/mall/order/pay',
        method: 'post',
        data: params
    })
}

// 申请退款
export function orderRefund(params) {
    return request({
        url: '/v1/mall/order/refund',
        method: 'post',
        data: params
    })
}


// 收货地址列表
export function getAddressList() {
    return request({
        url: '/v1/mall/address/list',
        method: 'get',
    })
}

// 默认收货地址
export function getMrAddress() {
    return request({
        url: '/v1/mall/address',
        method: 'get',
    })
}

// 新增或编辑地址
export function operateAddress(params) {
    return request({
        url: '/v1/mall/address',
        method: 'post',
        data: params
    })
}

// 删除收货地址
export function deleteAddress(id) {
    return request({
        url: '/v1/mall/address/' + id,
        method: 'delete'
    })
}

// 快递详情
export function expressInfo(orderId) {
    return request({
        url: '/v1/mall/express/info?orderId=' + orderId,
        method: 'get'
    })
}

// 收藏列表
export function getCollectList(params) {
    return request({
        url: '/v1/mall/collect/list?page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

// 浏览列表
export function getBrowseList(params) {
    return request({
        url: '/v1/mall/browse/list?page=' + params.page + '&rows=' + params.rows,
        method: 'get'
    })
}

// 支付确认
export function payOrderConfirm(params) {
    return request({
        url: '/v1/mall/order/pay/confirm',
        method: 'post',
        data: params
    })
}

