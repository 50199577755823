<template>
  <div class="list_box">
     <div class="title">
        <img src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>{{$route.params.id == 1?'收藏列表':'浏览列表'}}</span>
     </div>
       <div class="goodsList">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
               v-if="goodsList.length > 0"
            >
              <van-cell v-for="(item, index) in goodsList" :key="index" @click="goGoodDetails(item.id)">
                <div class="good_nav" >
                  <div class="good_img">
                    <img :src="item.topPic" alt="" srcset="">
                  </div>
                  <div class="good_info">
                    <div class="good_title">
                        {{item.name}}
                    </div>
                    <div class="tags" v-if="item.originPrice">
                        <span>限时低价</span> 
                    </div>
                    <div class="price">
                        <span class="z_price">￥{{item.price}}</span> 
                        <span class="j_price" v-if="item.originPrice">￥{{item.originPrice}}</span>
                    </div>
                  </div>
                </div>
              </van-cell>
            </van-list>
            
            <div v-if="goodsList.length == 0">
              <van-empty description="暂无商品" />
            </div>
            <bottomList></bottomList>
       </div>
  </div>
</template>

<script>
import { getCollectList, getBrowseList } from '@/api/order'
export default {
  name: "collectlist",

  data() {
    return {
        params: {
            page: 1,
            rows: 10
        },
        goodsList: [],
        loading: false,
        finished: true,
    };
  },

  mounted() {
    if(this.$route.params.id) {
        localStorage.setItem('iscollect', this.$route.params.id)
    } else {
        this.$route.params.id = localStorage.getItem('iscollect')
    }
    this.getList()
  },

  methods: {
    // 加载更多
    onLoad() {
      if (!this.finished) {
        this.params.page += 1
        this.loading = true
        if (this.$route.params.id == 1) {
            getCollectList(this.params).then(res => {
            this.goodsList = this.goodsList.concat(res.data.list)
                // 加载状态结束
            this.loading = false;
            // 数据全部加载完成
            if (res.data.list.length !== this.params.rows) {
                this.finished = true
            }
            })
        } else {
            getBrowseList(this.params).then(res => {
            this.goodsList = this.goodsList.concat(res.data.list)
                // 加载状态结束
            this.loading = false;
            // 数据全部加载完成
            if (res.data.list.length !== this.params.rows) {
                this.finished = true
            }
            })
        }
      }
    },
    getList() {
        var that = this
        if (this.$route.params.id == 1) {
            // 收藏
            getCollectList(that.params).then(res => {
                this.goodsList = res.data.list
                if (res.data.list.length == this.params.rows) {
                    this.finished = false
                }
            })
        } else {
            // 浏览
            getBrowseList(that.params).then(res => {
                this.goodsList = res.data.list
                if (res.data.list.length == this.params.rows) {
                    this.finished = false
                }
            })
        }
    },
    // 跳转商品详情
    goGoodDetails(id) {
      this.$router.push({
        name: `goodsdetail`,
        params: {
          id: id
        }
      })
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.title{
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 0rem;
    z-index: 11;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    img{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
}
.list_box{
    width: 100vw;
    padding-top: 50rem;
    background: #F6F7FA;
}
  .goodsList{
    width: 100vw;
    background: #fff;
    overflow-y: auto;
    .good_nav{
      display: flex;
      margin-bottom: 18rem;
      .good_img{
        img {
          width: 72rem;
          height: 72rem;
          margin-left: 13rem;
        }
      }
      .good_info{
        width: calc(100% - 100rem);
        margin-left: 6rem;
        .good_title{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 13rem;
          color: #333333;
          line-height: 18rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .tags{
          margin-top: 4rem;
          span{
            padding: 2rem 6rem;
            background: rgba(187,21,21,0.1);
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #FF3C3C;
          }
        }
        .price{
          margin-top: 6rem;
          .z_price{
            font-family: DINPro, DINPro;
            font-weight: 500;
            font-size: 15rem;
            color: #000000;
          }
          .j_price{
            font-family: DINPro, DINPro;
            font-weight: 400;
            font-size: 13rem;
            color: #C5C8D3;
            line-height: 17rem;
            margin-left: 4rem;
            position: relative;
            &::after {
              position: absolute;
              left: 4rem;
              top: 8rem;
              content: '';
              width: 30rem;
              height: 1rem;
              background: #C5C8D3;
            }
          }
        }
      }
    }
  }
</style>
